import React, { useEffect, useState } from "react";
import "./style.css";
import { Button } from "../../components/Button/Button";
import { Footer } from "../../components/Footer/Footer";
import { Link, useNavigate } from "react-router-dom";
import api from "../../AxiosInstance";
import { Loader } from "../../components/Loader/Loader";

export const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  const [errors, setErrors] = useState({});
  const [apiError, setApiError] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();
  const isLoggedIn = localStorage.getItem("isLoggedIn");

  const validations = () => {
    const errors = {};

    // Validate email
    if (!email) {
      errors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      errors.email = "Email address is invalid";
    }

    return errors;
  };

  const forgotPassword = () => {
    const validationError = validations();
    if (Object.keys(validationError).length === 0) {
      setIsLoading(true);
      const payload = {
        email: email,
      };

      localStorage.setItem("forgotPasswordEmail", email);

      api
        .post("/forgot-password", payload)
        .then((response) => {
          setIsLoading(false);
          setErrors({});
          setApiError("");
          navigate("/confirm-code");
        })
        .catch((error) => {
          setIsLoading(false);
          setApiError(error.response.data.message);
        });
    } else {
      setErrors(validationError);
    }
  };

  const handleEmailChange = (e) => {
    setErrors({ ...errors, email: null });
    setEmail(e.target.value);
    setApiError("");
  };

  useEffect(() => {
    if (isLoggedIn === "true") {
      navigate("/");
    }
    document.title = "Forgot Password - AfterGrad";
  }, []);

  return (
    <div className="forgot-page">
      {isLoading && <Loader />}
      <div className="main-forgot">
        <div className="forgot-heading">Forgot Password</div>
        <div className="forgot-modal">
          <div className="forgot-input-box">
            <div className="forgot-input-label">Email</div>
            <input
              type="text"
              placeholder="Enter Email"
              className="forgot-input-field"
              onChange={(e) => handleEmailChange(e)}
            />
            {errors.email != null && (
              <div className="error">{errors.email}</div>
            )}
          </div>
          {apiError != "" && <div className="error-text">{apiError}</div>}
          <div className="send-code-text">
            We’ll send a verification code to this email if it matches an
            existing AfterGrad account.
          </div>
          {/* <Link to="/confirm-code"> */}
          <div className="forgot-submit-btn" onClick={() => forgotPassword()}>
            <Button text="Next" />
          </div>
          {/* </Link> */}
          <Link to="/login">
            <div className="back-btn">
              <Button text="Back" />
            </div>
          </Link>
        </div>
      </div>
      <Footer />
    </div>
  );
};
