import React from "react";
import "./style.css";

export const Selector = (props) => {
  const { selectedTab, changeTab, selectedMainTab } = props;
  return (
    <div className="selector">
      <div
        className={"dept-opt " + `${(selectedTab == 1  || selectedTab == 3) && "selected"}`}
        onClick={() => (selectedMainTab == 1 ? changeTab(1) : changeTab(3))}
      >
        {selectedMainTab == 1
          ? "COMPARE OR EXPLORE DEPARTMENTS"
          : "EXPLORE POSITIONS(JOBS)"}
      </div>
      <div
        className={"dept-opt " + `${(selectedTab == 2 || selectedTab == 4) && "selected"}`}
        onClick={() => (selectedMainTab == 1 ? changeTab(2) : changeTab(4))}
      >
        {selectedMainTab == 1
          ? "COMPARE OR EXPLORE UNIVERSITIES"
          : "EXPLORE COMPANIES"}
      </div>
    </div>
  );
};
