import React from 'react';
import "./style.css";

export const Footer = (props) => {
  const {background, color} = props;
  return (
    <div className='footer' style={{background, color }}>
        <div className='main-footer'>
            &#169; Copyright 2024
        </div>
    </div>
  )
}
