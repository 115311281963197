import React, { useEffect, useState } from "react";
import "./style.css";
import { Button } from "../../components/Button/Button";
import { Footer } from "../../components/Footer/Footer";
import { Link, useNavigate } from "react-router-dom";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import api from "../../AxiosInstance";
import { Loader } from "../../components/Loader/Loader";

export const Register = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const isLoggedIn = localStorage.getItem("isLoggedIn");
  const [errors, setErrors] = useState({});
  const [apiError, setApiError] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();

  const validations = () => {
    const errors = {};

    if (!firstName) {
      errors.firstName = "First name is required";
    }

    if (!lastName) {
      errors.lastName = "Last name is required";
    }

    // Validate email
    if (!email) {
      errors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      errors.email = "Email address is invalid";
    }

    // Validate password
    if (!password) {
      errors.password = "Password is required";
    }

    if (!confirmPassword) {
      errors.confirmPassword = "Confirm password is required";
    } else if (confirmPassword != password) {
      errors.confirmPassword = "Passwords must be same";
    }

    return errors;
  };

  const register = () => {
    const validationError = validations();
    if (Object.keys(validationError).length === 0) {
      setIsLoading(true);
      const payload = {
        name: firstName + " " + lastName,
        email: email,
        password: password,
        confirm_password: confirmPassword,
      };
      api
        .post("/register", payload)
        .then((response) => {
          localStorage.setItem("isLoggedIn", true);
          localStorage.setItem("accessToken", response.token);
          setIsLoading(false);
          setErrors({});
          setApiError("");
          navigate("/");
        })
        .catch((error) => {
          setIsLoading(false);
          setApiError(error.response.data.message);
        });
    } else {
      setErrors(validationError);
    }
  };

  const handleFirstNameChange = (e) => {
    setErrors({ ...errors, firstName: null });
    setFirstName(e.target.value);
    setApiError("");
  };

  const handleLastNameChange = (e) => {
    setErrors({ ...errors, lastName: null });
    setLastName(e.target.value);
    setApiError("");
  };

  const handleEmailChange = (e) => {
    setErrors({ ...errors, email: null });
    setEmail(e.target.value);
    setApiError("");
  };

  const handlePasswordChange = (e) => {
    setErrors({ ...errors, password: null });
    setPassword(e.target.value);
    setApiError("");
  };

  const handleConfirmPasswordChange = (e) => {
    setErrors({ ...errors, confirmPassword: null });
    setConfirmPassword(e.target.value);
    setApiError("");
  };

  useEffect(() => {
    if (isLoggedIn === "true") {
      navigate("/");
    }
    document.title = "Register - AfterGrad";
  }, []);

  return (
    <div className="register-page">
      {isLoading && <Loader />}
      <div className="main-register">
        <div className="register-heading">Register</div>
        <div className="register-modal">
          <div className="modal-inner-container">
            <div className="input-box-1">
              <div className="register-input-box">
                <div className="register-input-label">First Name</div>
                <input
                  type="text"
                  placeholder="Enter First Name"
                  className="register-input-field"
                  onChange={(e) => handleFirstNameChange(e)}
                />
                {errors.email != null && (
                  <div className="error">{errors.firstName}</div>
                )}
              </div>
              <div className="register-input-box">
                <div className="register-input-label">Last Name</div>
                <input
                  type="text"
                  placeholder="Enter Last Name"
                  className="register-input-field"
                  onChange={(e) => handleLastNameChange(e)}
                />
                {errors.lastName != null && (
                  <div className="error">{errors.lastName}</div>
                )}
              </div>
            </div>
            <div className="register-input-box">
              <div className="register-input-label">Email</div>
              <input
                type="text"
                placeholder="Enter Email"
                className="register-input-field"
                onChange={(e) => handleEmailChange(e)}
              />
              {errors.email != null && (
                <div className="error">{errors.email}</div>
              )}
            </div>
            <div className="register-input-box">
              <div className="register-input-label">Password</div>
              <div className="input-text-box">
                <input
                  type={showPassword ? "text" : "password"}
                  placeholder="Enter Password"
                  className="register-input-field"
                  onChange={(e) => handlePasswordChange(e)}
                />
                <div
                  className="input-icon"
                  onClick={() => setShowPassword(!showPassword)}
                >
                  {showPassword ? <FaEyeSlash /> : <FaEye />}
                </div>
              </div>
              {errors.password != null && (
                <div className="error">{errors.password}</div>
              )}
            </div>
            <div className="register-input-box">
              <div className="register-input-label"> Confirm Password</div>
              <div className="input-text-box">
                <input
                  type={showConfirmPassword ? "text" : "password"}
                  placeholder="Enter Password"
                  className="register-input-field"
                  onChange={(e) => handleConfirmPasswordChange(e)}
                />
                <div
                  className="input-icon"
                  onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                >
                  {showConfirmPassword ? <FaEyeSlash /> : <FaEye />}
                </div>
              </div>
              {errors.confirmPassword != null && (
                <div className="error">{errors.confirmPassword}</div>
              )}
            </div>
            {apiError != "" && <div className="error-text">{apiError}</div>}
            <div className="register-submit-btn">
              <Button text="Register" onClick={() => register()} />
            </div>
            <div className="register-link">
              Already on AfterGrad?
              <Link to="/login">
                <span className="text-link">&nbsp; Sign in</span>
              </Link>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};
