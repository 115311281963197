import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Footer } from "../../components/Footer/Footer";


export const Dashboard = () => {
  const isLoggedIn = localStorage.getItem("isLoggedIn");
  const navigate = useNavigate();
  useEffect(() => {
    if (isLoggedIn !== "true") {
      navigate('/')
    }
    document.title = "Home - Dashboard";
  }, []);

  return (
    <div className="dashboard">
      <div className="inner-dashboard">
        <div className="headline"> Coming soon...</div>
      </div>
      <Footer color="white"/>
    </div>
  );
};
