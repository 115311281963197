import React from "react";
import "./style.css";

export const Button = (props) => {
  const { text, icon, type, disabled, onClick = () => {} } = props;
  return (
    <div className={disabled ? "main-button disabled" : "main-button"}>
      <button
        type={type}
        className={disabled ? "inner-button disabled" : "inner-button"}
        disabled={disabled}
        onClick={onClick}
      >
        {text}
      </button>
      {icon && <img src={icon} className="btn-icon" />}
    </div>
  );
};
