import React, { useEffect, useState } from "react";
import "./style.css";
import { Button } from "../../components/Button/Button";
import { Footer } from "../../components/Footer/Footer";
import { Link, useNavigate } from "react-router-dom";
import api from "../../AxiosInstance";
import { Loader } from "../../components/Loader/Loader";

export const ConfirmCode = () => {
  const [code, setCode] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [apiError, setApiError] = useState("");
  const navigate = useNavigate();

  const isLoggedIn = localStorage.getItem("isLoggedIn");

  const forgotPasswordEmail = localStorage.getItem("forgotPasswordEmail");

  const resendCode = () => {
    setIsLoading(true);
    const payload = {
      email: forgotPasswordEmail,
    };
    api
      .post("/forgot-password", payload)
      .then((response) => {
        setIsLoading(false);
        setErrors({ ...errors, code: "Code sent, Please check!" });
        setApiError("");
      })
      .catch((error) => {
        setIsLoading(false);
        setApiError(error.response.data.message);
      });
  };

  const validations = () => {
    const errors = {};

    // Validate email
    if (!code) {
      errors.code = "Code is required";
    }

    return errors;
  };

  const confirmCode = () => {
    const validationError = validations();
    if (Object.keys(validationError).length === 0) {
      setIsLoading(true);
      const payload = {
        email: forgotPasswordEmail,
        code: code,
      };

      api
        .post("/verify-code", payload)
        .then((response) => {
          setIsLoading(false);
          setErrors({});
          setApiError("");
          navigate("/reset-password");
        })
        .catch((error) => {
          setApiError(error.response.data.message);
          setIsLoading(false);
        });
    } else {
      setErrors(validationError);
    }
  };

  const handleCodeChange = (e) => {
    setErrors({ ...errors, code: null });
    setCode(e.target.value);
    setApiError("");
  };

  useEffect(() => {
    if (isLoggedIn === "true") {
      navigate("/");
    }
    document.title = "Verify Code - AfterGrad";
  }, []);
  return (
    <div className="confirm-page">
      {isLoading && <Loader />}
      <div className="main-confirm">
        <div className="confirm-heading">Verification Code</div>
        <div className="confirm-modal">
          <div className="send-code-text">
            Please enter the 6-digit verification code we sent to your email.
          </div>
          <div className="confirm-input-box">
            <div className="confirm-input-label">Enter Code</div>
            <input
              type="text"
              placeholder="Enter Code"
              className="confirm-input-field"
              onChange={(e) => handleCodeChange(e)}
              maxLength={6}
            />
            {errors.code != null && <div className="error">{errors.code}</div>}
          </div>
          {apiError != "" && <div className="error-text">{apiError}</div>}
          <div className="send-code-text" onClick={() => resendCode()}>
            Didn't receive the code?{" "}
            <span className="text-link"> &nbsp;Resend Code</span>
          </div>
          <div className="confirm-submit-btn">
            <Button text="Submit" onClick={(e) => confirmCode()} />
          </div>
          <Link to="/forgot-password">
            <div className="back-btn">
              <Button text="Back" />
            </div>
          </Link>
        </div>
      </div>
      <Footer />
    </div>
  );
};
