import React, { useEffect, useState } from "react";
import "./style.css";
import { Button } from "../../components/Button/Button";
import { Footer } from "../../components/Footer/Footer";
import { Link, useNavigate } from "react-router-dom";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import api from "../../AxiosInstance";
import { Loader } from "../../components/Loader/Loader";

export const ResetPassword = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [errors, setErrors] = useState({});
  const [apiError, setApiError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isPasswordReset, setIsPasswordReset] = useState(false);

  const navigate = useNavigate();
  const isLoggedIn = localStorage.getItem("isLoggedIn");
  const forgotPasswordEmail = localStorage.getItem("forgotPasswordEmail");

  const validations = () => {
    const errors = {};

    // Validate password
    if (!password) {
      errors.password = "Password is required";
    }

    if (!confirmPassword) {
      errors.confirmPassword = "Confirm password is required";
    } else if (confirmPassword != password) {
      errors.confirmPassword = "Passwords must be same";
    }

    return errors;
  };

  const resetPassword = () => {
    const validationError = validations();
    if (Object.keys(validationError).length === 0) {
      setIsLoading(true);
      const payload = {
        email: forgotPasswordEmail,
        password: password,
        confirm_password: confirmPassword,
      };

      api
        .post("/reset-password", payload)
        .then((response) => {
          setIsLoading(false);
          setErrors({});
          setApiError("");
          setPassword("");
          setConfirmPassword("");
          setIsPasswordReset(true);
        })
        .catch((error) => {
          setIsLoading(false);
          setIsPasswordReset(false);
          setApiError(error.response.data.message);
        });
    } else {
      setIsPasswordReset(false);
      setErrors(validationError);
    }
  };

  const handlePasswordChange = (e) => {
    setErrors({ ...errors, password: null });
    setPassword(e.target.value);
    setApiError("");
    setIsPasswordReset(false);
  };

  const handleConfirmPasswordChange = (e) => {
    setErrors({ ...errors, confirmPassword: null });
    setConfirmPassword(e.target.value);
    setApiError("");
    setIsPasswordReset(false);
  };

  useEffect(() => {
    if (isLoggedIn === "true") {
      navigate("/");
    }
    document.title = "Reset Password - AfterGrad";
  }, []);
  return (
    <div className="reset-page">
      {isLoading && <Loader />}
      <div className="main-reset">
        <div className="reset-heading">Reset Password</div>
        <div className="reset-modal">
          <div className="reset-input-box">
            <div className="reset-input-label">New Password</div>
            <input
              type="password"
              placeholder="Enter New Password"
              className="reset-input-field"
              onChange={(e) => handlePasswordChange(e)}
            />
            {errors.password != null && (
              <div className="error">{errors.password}</div>
            )}
          </div>
          <div className="reset-input-box">
            <div className="reset-input-label">Confirm Password</div>
            <div className="input-text-box">
              <input
                type={showPassword ? "text" : "password"}
                placeholder="Confirm Password"
                className="reset-input-field"
                onChange={(e) => handleConfirmPasswordChange(e)}
              />
              <div
                className="input-icon"
                onClick={() => setShowPassword(!showPassword)}
              >
                {showPassword ? <FaEyeSlash /> : <FaEye />}
              </div>
            </div>
            {errors.confirmPassword != null && (
              <div className="error">{errors.confirmPassword}</div>
            )}
          </div>
          {isPasswordReset && (
            <div className="error-text" style={{color: "green"}}>Password reset successfully!</div>
          )}
          {apiError != "" && <div className="error-text">{apiError}</div>}
          <div className="reset-submit-btn" onClick={() => resetPassword()}>
            <Button text="Submit" />
          </div>
          <Link to="/login">
            <div className="back-btn">
              <Button text="Back to Login" />
            </div>
          </Link>
        </div>
      </div>
      <Footer />
    </div>
  );
};
