import React, { useEffect, useRef, useState } from "react";
import "./style.css";
import { ProgressBar } from "../../components/ProgressBar/ProgressBar";
import { Footer } from "../../components/Footer/Footer";
import { Loader } from "../../components/Loader/Loader";
import { useLocation, useParams } from "react-router-dom";
import ResultLoader from "../../components/ResultLoader/ResultLoader";
import ContentLoader from "react-content-loader";

import api from "../../AxiosInstance";
import { ErrorPage } from "../../components/ErrorPage/ErrorPage";

export const ResultPage = () => {
  const [showMenu, setShowMenu] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedList, setSelectedList] = useState(0);
  const [afterGradData1, setAfterGradData1] = useState(null);
  const [afterGradData2, setAfterGradData2] = useState(null);
  const [resultBodyTop, setResultBodyTop] = useState(null);
  const [showErrorPage, setShowErrorPage] = useState(false);
  const resultHead = useRef(null);
  const location = useLocation();

  const {
    college,
    college1,
    department,
    department1,
    company,
    position,
    departmentCount,
    selectedMainTab,
  } = location.state || {};

  const {
    collegeUid,
    collegeUid1,
    departmentUid,
    departmentUid1,
    companyUid,
    positionUid,
  } = useParams();

  const handleRef = (value) => {
    window.scrollTo({
      top: value * 450,
      behavior: "smooth", // Smooth scroll effect
    });
  };

  const selectResultIndex = (i) => {
    handleRef(i);
    setSelectedList(i);
  };

  const selectNavItem = () => {
    if (window.pageYOffset < 400) {
      setSelectedList(0);
    } else if (window.pageYOffset > 400 && window.pageYOffset < 800) {
      setSelectedList(1);
    } else if (window.pageYOffset > 800 && window.pageYOffset < 1200) {
      setSelectedList(2);
    } else {
      setSelectedList(3);
    }
  };

  const updateHeight = (height = 0) => {
    if (resultHead.current) {
      setResultBodyTop(resultHead.current.clientHeight + height);
    }
  };

  useEffect(() => {
    updateHeight(20);
    window.addEventListener("scroll", selectNavItem);
    window.addEventListener("resize", updateHeight);
    return () => {
      window.removeEventListener("scroll", selectNavItem);
      window.removeEventListener("resize", updateHeight);
    };
  }, []);

  const resultIndex = [
    "Company & position specific breakdown",
    "Company breakdown",
    "position breakdown",
    "Characteristic Carreer paths",
  ];

  const result1Index = [
    "College & Department specific breakdown",
    "College breakdown",
    "Department breakdown",
  ];

  const fetchAfterGradData = () => {
    setIsLoading(true);
    api
      .get("/fetchMultipleAfterGradData", {
        params: {
          colleges: [collegeUid, collegeUid1],
          departments: [departmentUid, departmentUid1],
        },
      })
      .then((response) => {
        setAfterGradData1(response.data.data[0]);
        if (departmentCount > 1) {
          setAfterGradData2(response.data.data[1]);
        }
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setShowErrorPage(true);
        setIsLoading(false);
      });
  };

  const fetchBeforeGradData = () => {
    setIsLoading(true);
    api
      .get("/fetchBeforeGradData", {
        params: {
          company: companyUid,
          position: positionUid,
        },
      })
      .then((response) => {
        setAfterGradData1(response.data.data);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setShowErrorPage(true);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (selectedMainTab == 1) {
      document.title =
        college +
        " " +
        college1 +
        " " +
        department +
        " " +
        department1 +
        " - AfterGrad";
      fetchAfterGradData();
    } else {
      document.title = company + " " + position + " - AfterGrad";
      fetchBeforeGradData();
    }
  }, []);
  return (
    <>
      {showErrorPage ? (
        <ErrorPage />
      ) : (
        <div className="result-page">
          <div className="result-head" ref={resultHead}>
            <div
              className="inner-result-head"
              style={{
                justifyContent:
                  departmentCount == 1 ? "flex-start" : "space-between",
              }}
            >
              {departmentCount == 1 && (
                <>
                  <div className="head-section-1">
                    {position ? (
                      <div className="text-1">{company + " / " + position}</div>
                    ) : (
                      <div className="text-1">{company}</div>
                    )}

                    {department ? (
                      <div className="text-1">
                        {college + " / " + department}
                      </div>
                    ) : (
                      <div className="text-1">{college}</div>
                    )}
                    {afterGradData1?.total_professional_count ? (
                      <div className="text-2">
                        Based on career analysis of{" "}
                        {afterGradData1?.total_professional_count} professionals
                      </div>
                    ) : (
                      isLoading && (
                        <ContentLoader
                          height={"20"}
                          width={"80%"}
                          backgroundColor="#dae1e6"
                          foregroundColor="grey"
                        >
                          <rect
                            x="0"
                            y="5"
                            rx="4"
                            ry="4"
                            width="70%"
                            height="20"
                          />
                        </ContentLoader>
                      )
                    )}
                  </div>
                  {afterGradData1?.company_position_breakdown?.length > 0 && (
                    <div className="head-section-2">
                      <div className="text-3">
                        {"%" +
                          afterGradData1?.company_position_breakdown[0]
                            .professional_percent +
                          " " +
                          afterGradData1?.company_position_breakdown[0]
                            .org_role}
                      </div>
                      <div className="text-3">
                        @
                        {afterGradData1?.company_position_breakdown[0].org_name}
                      </div>
                    </div>
                  )}
                </>
              )}
              {departmentCount > 1 && (
                <>
                  <div
                    className="head-section-1"
                    style={{ textAlign: "center", borderBottom: "none" }}
                  >
                    {department ? (
                      <div className="text-1">
                        {college + " / " + department}
                      </div>
                    ) : (
                      <div className="text-1">{college}</div>
                    )}
                    {afterGradData1?.total_professional_count ? (
                      <div className="text-2">
                        Based on carreer analysis of{" "}
                        {afterGradData1?.total_professional_count} professionals
                      </div>
                    ) : (
                      isLoading && (
                        <ContentLoader
                          height={"20"}
                          width={"100%"}
                          backgroundColor="#dae1e6"
                          foregroundColor="grey"
                        >
                          <rect
                            x="0"
                            y="5"
                            rx="4"
                            ry="4"
                            width="100%"
                            height="20"
                          />
                        </ContentLoader>
                      )
                    )}
                  </div>
                  <div className="vs-text">VS</div>
                  <div
                    className="head-section-1"
                    style={{ textAlign: "center", borderBottom: "none" }}
                  >
                    {department1 ? (
                      <div className="text-1">
                        {college1 + " / " + department1}
                      </div>
                    ) : (
                      <div className="text-1">{college1}</div>
                    )}
                    {afterGradData2?.total_professional_count ? (
                      <div className="text-2">
                        Based on carreer analysis of{" "}
                        {afterGradData2?.total_professional_count} professionals
                      </div>
                    ) : (
                      isLoading && (
                        <ContentLoader
                          height={"20"}
                          width={"100%"}
                          backgroundColor="#dae1e6"
                          foregroundColor="grey"
                        >
                          <rect
                            x="0"
                            y="5"
                            rx="4"
                            ry="4"
                            width="100%"
                            height="20"
                          />
                        </ContentLoader>
                      )
                    )}
                  </div>
                </>
              )}
            </div>
          </div>
          {departmentCount == 1 && (
            <div className="result-body">
              <div
                className={
                  "result-body-left " + `${showMenu ? "show" : "hide"}`
                }
                style={{ top: 75 + resultBodyTop }}
              >
                {selectedMainTab == 1 ? (
                  <div className="result-index">
                    {resultIndex.map((value, i) => {
                      return (
                        <div
                          className={
                            "result-index-list " +
                            `${selectedList == i ? "selected-list" : ""}`
                          }
                          onClick={() => selectResultIndex(i)}
                          key={i}
                        >
                          {value}
                        </div>
                      );
                    })}
                  </div>
                ) : (
                  <div className="result-index">
                    {result1Index.map((value, i) => {
                      return (
                        <div
                          className={
                            "result-index-list " +
                            `${selectedList == i ? "selected-list" : ""}`
                          }
                          onClick={() => selectResultIndex(i)}
                          key={i}
                        >
                          {value}
                        </div>
                      );
                    })}
                  </div>
                )}
              </div>
              <div
                className="result-body-right"
                style={{ top: resultBodyTop - 5 }}
              >
                <div className="result-data-1">
                  <div className="result-data-head">
                    <div className="text-1">
                      {selectedMainTab === 1
                        ? "Company & position specific breakdown"
                        : "College & Department specific breakdown"}
                    </div>
                    <div className="text-2">
                      {selectedMainTab === 1
                        ? "In which position & company they work?"
                        : "In which department & college they study?"}
                    </div>
                  </div>
                  {selectedMainTab == 1 ? (
                    <div className="result-data-body">
                      {isLoading ? (
                        <ResultLoader />
                      ) : afterGradData1?.company_position_breakdown?.length >
                        0 ? (
                        afterGradData1?.company_position_breakdown.map(
                          (value, key) => {
                            return (
                              <ProgressBar
                                key={key}
                                text={
                                  "%" +
                                  value?.professional_percent +
                                  " - " +
                                  value.professional_count +
                                  " graduates " +
                                  " | " +
                                  value.org_role +
                                  " @" +
                                  value.org_name
                                }
                                percentage={value.professional_percent + "%"}
                              />
                            );
                          }
                        )
                      ) : (
                        <div className="no-data-text">No Data Available</div>
                      )}
                    </div>
                  ) : (
                    <div className="result-data-body">
                      {isLoading ? (
                        <ResultLoader />
                      ) : afterGradData1?.college_dept_breakdown?.length > 0 ? (
                        afterGradData1?.college_dept_breakdown.map(
                          (value, key) => {
                            return (
                              <ProgressBar
                                key={key}
                                text={
                                  "%" +
                                  value?.professional_percent +
                                  " - " +
                                  value.professional_count +
                                  " employee" + (value.professional_count > 1 ? "s" : "") +
                                  " | " +
                                  value.college_dept +
                                  " @" +
                                  value.college_name
                                }
                                percentage={value.professional_percent + "%"}
                              />
                            );
                          }
                        )
                      ) : (
                        <div className="no-data-text">No Data Available</div>
                      )}
                    </div>
                  )}
                </div>
                <div className="result-data-1">
                  <div className="result-data-head">
                    <div className="text-1">
                      {selectedMainTab == 1
                        ? "Company breakdown"
                        : "College breakdown"}
                    </div>
                    <div className="text-2">
                      {selectedMainTab == 1
                        ? "In which company they work?"
                        : "In which college they study?"}
                    </div>
                  </div>
                  {selectedMainTab == 1 ? (
                    <div className="result-data-body">
                      {isLoading ? (
                        <ResultLoader />
                      ) : afterGradData1?.company_breakdown?.length > 0 ? (
                        afterGradData1?.company_breakdown.map((value, key) => {
                          return (
                            <ProgressBar
                              key={key}
                              text={
                                "%" +
                                value.professional_percent +
                                " - " +
                                value.professional_count +
                                " graduates " +
                                " | " +
                                " @" +
                                value.org_name
                              }
                              percentage={value.professional_percent + "%"}
                            />
                          );
                        })
                      ) : (
                        <div className="no-data-text">No Data Available</div>
                      )}
                    </div>
                  ) : (
                    <div className="result-data-body">
                      {isLoading ? (
                        <ResultLoader />
                      ) : afterGradData1?.college_breakdown?.length > 0 ? (
                        afterGradData1?.college_breakdown.map((value, key) => {
                          return (
                            <ProgressBar
                              key={key}
                              text={
                                "%" +
                                value.professional_percent +
                                " - " +
                                value.professional_count +
                                " employee" + (value.professional_count > 1 ? "s" : "") +
                                " | " +
                                " @" +
                                value.college_name
                              }
                              percentage={value.professional_percent + "%"}
                            />
                          );
                        })
                      ) : (
                        <div className="no-data-text">No Data Available</div>
                      )}
                    </div>
                  )}
                </div>
                <div className="result-data-2">
                  <div className="result-data-head">
                    <div className="text-1">
                      {selectedMainTab == 1
                        ? "Position breakdown"
                        : "Department breakdown"}
                    </div>
                    <div className="text-2">
                      {selectedMainTab == 1
                        ? "On which position they work?"
                        : "In Which department they study?"}
                    </div>
                  </div>
                  {selectedMainTab == 1 ? (
                    <div className="result-data-body">
                      {isLoading ? (
                        <ResultLoader />
                      ) : afterGradData1?.position_breakdown?.length > 0 ? (
                        afterGradData1?.position_breakdown.map((value, key) => {
                          console.log();
                          return (
                            <ProgressBar
                              key={key}
                              text={
                                "%" +
                                value.professional_percent +
                                " - " +
                                value.professional_count +
                                " graduates " +
                                " | " +
                                value.org_role
                              }
                              percentage={value.professional_percent + "%"}
                            />
                          );
                        })
                      ) : (
                        <div className="no-data-text">No Data Available</div>
                      )}
                    </div>
                  ) : (
                    <div className="result-data-body">
                      {isLoading ? (
                        <ResultLoader />
                      ) : afterGradData1?.dept_breakdown?.length > 0 ? (
                        afterGradData1?.dept_breakdown.map((value, key) => {
                          console.log();
                          return (
                            <ProgressBar
                              key={key}
                              text={
                                "%" +
                                value.professional_percent +
                                " - " +
                                value.professional_count +
                                " employee" + (value.professional_count > 1 ? "s" : "") +
                                " | " +
                                value.ed_dept
                              }
                              percentage={value.professional_percent + "%"}
                            />
                          );
                        })
                      ) : (
                        <div className="no-data-text">No Data Available</div>
                      )}
                    </div>
                  )}
                </div>
                {selectedMainTab == 1 && (
                  <div className="result-data-1">
                    <div className="result-data-head">
                      <div className="text-1">Characteristic Carreer paths</div>
                      <div className="text-2">
                        How is their after graduation carreer paths in terms of
                        position & company?
                      </div>
                    </div>
                    <div className="main-data-body">
                      <div className="data-body-label">
                        Up to 1 year after grad
                      </div>
                      <div className="result-data-body border-bottom">
                        {isLoading ? (
                          <ResultLoader />
                        ) : afterGradData1?.after_one_year_data?.length > 0 ? (
                          afterGradData1?.after_one_year_data.map(
                            (value, key) => {
                              return (
                                <ProgressBar
                                  key={key}
                                  text={
                                    "%" +
                                    value.professional_percent +
                                    " - " +
                                    value.professional_count +
                                    " graduates " +
                                    " | " +
                                    " @" +
                                    value.org_name
                                  }
                                  percentage={value.professional_percent + "%"}
                                />
                              );
                            }
                          )
                        ) : (
                          <div className="no-data-text">No Data Available</div>
                        )}
                      </div>
                    </div>
                    <div className="main-data-body">
                      <div className="data-body-label">
                        1 to 3 years after grad
                      </div>
                      <div className="result-data-body border-bottom">
                        {isLoading ? (
                          <ResultLoader />
                        ) : afterGradData1?.after_one_to_three_year_data
                            ?.length > 0 ? (
                          afterGradData1?.after_one_to_three_year_data.map(
                            (value, key) => {
                              return (
                                <ProgressBar
                                  key={key}
                                  text={
                                    "%" +
                                    value.professional_percent +
                                    " - " +
                                    value.professional_count +
                                    " graduates " +
                                    " | " +
                                    " @" +
                                    value.org_name
                                  }
                                  percentage={value.professional_percent + "%"}
                                />
                              );
                            }
                          )
                        ) : (
                          <div className="no-data-text">No Data Available</div>
                        )}
                      </div>
                    </div>
                    <div className="main-data-body">
                      <div className="data-body-label">
                        3 to 5 years after grad
                      </div>
                      <div className="result-data-body">
                        {isLoading ? (
                          <ResultLoader />
                        ) : afterGradData1?.after_three_to_five_year_data
                            ?.length > 0 ? (
                          afterGradData1?.after_three_to_five_year_data.map(
                            (value, key) => {
                              return (
                                <ProgressBar
                                  key={key}
                                  text={
                                    "%" +
                                    value.professional_percent +
                                    " - " +
                                    value.professional_count +
                                    " graduates " +
                                    " | " +
                                    " @" +
                                    value.org_name
                                  }
                                  percentage={value.professional_percent + "%"}
                                />
                              );
                            }
                          )
                        ) : (
                          <div className="no-data-text">No Data Available</div>
                        )}
                      </div>
                    </div>
                  </div>
                )}
                <Footer />
              </div>
            </div>
          )}
          {departmentCount > 1 && (
            <div
              className="multiple-result-body"
              style={{ marginTop: resultBodyTop }}
            >
              <div className="multiple-result-head">
                <div className="text-1">
                  Company & position specific breakdown
                </div>
                <div className="text-2">
                  In which position&company they work?
                </div>
              </div>
              <div className="multiple-result-data">
                <div className="multiple-data-body">
                  {isLoading ? (
                    <ResultLoader />
                  ) : afterGradData1?.company_position_breakdown?.length > 0 ? (
                    afterGradData1?.company_position_breakdown.map(
                      (value, key) => {
                        return (
                          <ProgressBar
                            key={key}
                            text={
                              "%" +
                              value?.professional_percent +
                              " - " +
                              value.professional_count +
                              " graduates " +
                              " | " +
                              value.org_role +
                              " @" +
                              value.org_name
                            }
                            percentage={value.professional_percent + "%"}
                          />
                        );
                      }
                    )
                  ) : (
                    <div className="no-data-text">No Data Available</div>
                  )}
                </div>
                <div className="multiple-data-body">
                  {isLoading ? (
                    <ResultLoader />
                  ) : afterGradData2?.company_position_breakdown?.length > 0 ? (
                    afterGradData2?.company_position_breakdown.map(
                      (value, key) => {
                        return (
                          <ProgressBar
                            key={key}
                            text={
                              "%" +
                              value?.professional_percent +
                              " - " +
                              value.professional_count +
                              " graduates " +
                              " | " +
                              value.org_role +
                              " @" +
                              value.org_name
                            }
                            percentage={value.professional_percent + "%"}
                          />
                        );
                      }
                    )
                  ) : (
                    <div className="no-data-text">No Data Available</div>
                  )}
                </div>
              </div>
              <div className="multiple-result-head">
                <div className="text-1">Company breakdown</div>
                <div className="text-2">In which company they work?</div>
              </div>
              <div className="multiple-result-data">
                <div className="multiple-data-body">
                  {isLoading ? (
                    <ResultLoader />
                  ) : afterGradData1?.company_breakdown?.length > 0 ? (
                    afterGradData1?.company_breakdown.map((value, key) => {
                      return (
                        <ProgressBar
                          key={key}
                          text={
                            "%" +
                            value?.professional_percent +
                            " - " +
                            value.professional_count +
                            " graduates " +
                            " | " +
                            "@ " +
                            value.org_name
                          }
                          percentage={value.professional_percent + "%"}
                        />
                      );
                    })
                  ) : (
                    <div className="no-data-text">No Data Available</div>
                  )}
                </div>
                <div className="multiple-data-body">
                  {isLoading ? (
                    <ResultLoader />
                  ) : afterGradData2?.company_breakdown?.length > 0 ? (
                    afterGradData2?.company_breakdown.map((value, key) => {
                      return (
                        <ProgressBar
                          key={key}
                          text={
                            "%" +
                            value?.professional_percent +
                            " - " +
                            value.professional_count +
                            " graduates " +
                            " | " +
                            "@ " +
                            value.org_name
                          }
                          percentage={value.professional_percent + "%"}
                        />
                      );
                    })
                  ) : (
                    <div className="no-data-text">No Data Available</div>
                  )}
                </div>
              </div>
              <div className="multiple-result-head">
                <div className="text-1">Position breakdown</div>
                <div className="text-2">On which position they work?</div>
              </div>
              <div className="multiple-result-data">
                <div className="multiple-data-body">
                  {isLoading ? (
                    <ResultLoader />
                  ) : afterGradData1?.position_breakdown?.length > 0 ? (
                    afterGradData1?.position_breakdown.map((value, key) => {
                      return (
                        <ProgressBar
                          key={key}
                          text={
                            "%" +
                            value?.professional_percent +
                            " - " +
                            value.professional_count +
                            " graduates " +
                            " | " +
                            value.org_role
                          }
                          percentage={value.professional_percent + "%"}
                        />
                      );
                    })
                  ) : (
                    <div className="no-data-text">No Data Available</div>
                  )}
                </div>
                <div className="multiple-data-body">
                  {isLoading ? (
                    <ResultLoader />
                  ) : afterGradData2?.position_breakdown?.length > 0 ? (
                    afterGradData2?.position_breakdown.map((value, key) => {
                      return (
                        <ProgressBar
                          key={key}
                          text={
                            "%" +
                            value?.professional_percent +
                            " - " +
                            value.professional_count +
                            " graduates " +
                            " | " +
                            value.org_role
                          }
                          percentage={value.professional_percent + "%"}
                        />
                      );
                    })
                  ) : (
                    <div className="no-data-text">No Data Available</div>
                  )}
                </div>
              </div>
              <div className="multiple-result-head">
                <div className="text-1">Characteristic Carreer paths</div>
                <div className="text-2">
                  How is their after graduation carreer paths in terms of
                  position & company?
                </div>
              </div>
              <div className="multiple-result-data">
                <div className="multiple-data-body">
                  <div className="main-data-body">
                    <div className="data-body-label">
                      Up to 1 year after grad
                    </div>
                    <div className="result-data-body border-bottom">
                      {isLoading ? (
                        <ResultLoader />
                      ) : afterGradData1?.after_one_year_data?.length > 0 ? (
                        afterGradData1?.after_one_year_data.map(
                          (value, key) => {
                            return (
                              <ProgressBar
                                key={key}
                                text={
                                  "%" +
                                  value.professional_percent +
                                  " - " +
                                  value.professional_count +
                                  " graduates " +
                                  " | " +
                                  " @" +
                                  value.org_name
                                }
                                percentage={value.professional_percent + "%"}
                              />
                            );
                          }
                        )
                      ) : (
                        <div className="no-data-text">No Data Available</div>
                      )}
                    </div>
                  </div>
                  <div className="main-data-body">
                    <div className="data-body-label">
                      1 to 3 years after grad
                    </div>
                    <div className="result-data-body border-bottom">
                      {isLoading ? (
                        <ResultLoader />
                      ) : afterGradData1?.after_one_to_three_year_data?.length >
                        0 ? (
                        afterGradData1?.after_one_to_three_year_data.map(
                          (value, key) => {
                            return (
                              <ProgressBar
                                key={key}
                                text={
                                  "%" +
                                  value.professional_percent +
                                  " - " +
                                  value.professional_count +
                                  " graduates " +
                                  " | " +
                                  " @" +
                                  value.org_name
                                }
                                percentage={value.professional_percent + "%"}
                              />
                            );
                          }
                        )
                      ) : (
                        <div className="no-data-text">No Data Available</div>
                      )}
                    </div>
                  </div>
                  <div className="main-data-body">
                    <div className="data-body-label">
                      3 to 5 years after grad
                    </div>
                    <div className="result-data-body">
                      {isLoading ? (
                        <ResultLoader />
                      ) : afterGradData1?.after_three_to_five_year_data
                          ?.length > 0 ? (
                        afterGradData1?.after_three_to_five_year_data.map(
                          (value, key) => {
                            return (
                              <ProgressBar
                                key={key}
                                text={
                                  "%" +
                                  value.professional_percent +
                                  " - " +
                                  value.professional_count +
                                  " graduates " +
                                  " | " +
                                  " @" +
                                  value.org_name
                                }
                                percentage={value.professional_percent + "%"}
                              />
                            );
                          }
                        )
                      ) : (
                        <div className="no-data-text">No Data Available</div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="multiple-data-body">
                  <div className="main-data-body">
                    <div className="data-body-label">
                      Up to 1 year after grad
                    </div>
                    <div className="result-data-body border-bottom">
                      {isLoading ? (
                        <ResultLoader />
                      ) : afterGradData2?.after_one_year_data?.length > 0 ? (
                        afterGradData2?.after_one_year_data.map(
                          (value, key) => {
                            return (
                              <ProgressBar
                                key={key}
                                text={
                                  "%" +
                                  value.professional_percent +
                                  " - " +
                                  value.professional_count +
                                  " graduates " +
                                  " | " +
                                  " @" +
                                  value.org_name
                                }
                                percentage={value.professional_percent + "%"}
                              />
                            );
                          }
                        )
                      ) : (
                        <div className="no-data-text">No Data Available</div>
                      )}
                    </div>
                  </div>
                  <div className="main-data-body">
                    <div className="data-body-label">
                      1 to 3 years after grad
                    </div>
                    <div className="result-data-body border-bottom">
                      {isLoading ? (
                        <ResultLoader />
                      ) : afterGradData2?.after_one_to_three_year_data?.length >
                        0 ? (
                        afterGradData2?.after_one_to_three_year_data.map(
                          (value, key) => {
                            return (
                              <ProgressBar
                                key={key}
                                text={
                                  "%" +
                                  value.professional_percent +
                                  " - " +
                                  value.professional_count +
                                  " graduates " +
                                  " | " +
                                  " @" +
                                  value.org_name
                                }
                                percentage={value.professional_percent + "%"}
                              />
                            );
                          }
                        )
                      ) : (
                        <div className="no-data-text">No Data Available</div>
                      )}
                    </div>
                  </div>
                  <div className="main-data-body">
                    <div className="data-body-label">
                      3 to 5 years after grad
                    </div>
                    <div className="result-data-body">
                      {isLoading ? (
                        <ResultLoader />
                      ) : afterGradData2?.after_three_to_five_year_data
                          ?.length > 0 ? (
                        afterGradData2?.after_three_to_five_year_data.map(
                          (value, key) => {
                            return (
                              <ProgressBar
                                key={key}
                                text={
                                  "%" +
                                  value.professional_percent +
                                  " - " +
                                  value.professional_count +
                                  " graduates " +
                                  " | " +
                                  " @" +
                                  value.org_name
                                }
                                percentage={value.professional_percent + "%"}
                              />
                            );
                          }
                        )
                      ) : (
                        <div className="no-data-text">No Data Available</div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
};
