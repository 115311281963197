import { Header } from "./components/Header/Header";
import { Route, Routes, Navigate } from "react-router-dom";
import "./App.css";
import { Homepage } from "./pages/Homepage/Homepage";
import { ResultPage } from "./pages/ResultPage/ResultPage";
import { Login } from "./pages/Login/Login";
import { Register } from "./pages/Register/Register";
import { ForgotPassword } from "./pages/ForgotPassword/ForgotPassword";
import { ResetPassword } from "./pages/ResetPassword/ResetPassword";
import { ConfirmCode } from "./pages/ConfirmCode/ConfirmCode";
import { Dashboard } from "./pages/Dashboard/Dashboard";
import { ErrorPage } from "./components/ErrorPage/ErrorPage";

function App({ component: Component, ...rest }) {
  const isLoggedIn = localStorage.getItem("isLoggedIn");
  return (
    <div className="App">
      <Header />
      <div className="body">
        <Routes>
          <Route exact path="/" element={<Homepage />} />
          <Route exact path="/dashboard" element={<Dashboard />} />
          <Route
            exact
            path="/explore_department/:collegeUid/:departmentUid"
            element={<ResultPage />}
          />
          <Route
            exact
            path="/compare_departments/:collegeUid/:departmentUid/:collegeUid1/:departmentUid1"
            element={<ResultPage />}
          />
          <Route
            exact
            path="/explore_university/:collegeUid"
            element={<ResultPage />}
          />
          <Route
            exact
            path="/compare_universities/:collegeUid/:collegeUid1"
            element={<ResultPage />}
          />
          <Route
            exact
            path="/explore_position/:companyUid/:positionUid"
            element={<ResultPage />}
          />
          <Route
            exact
            path="/explore_company/:companyUid"
            element={<ResultPage />}
          />
          <Route exact path="/login" element={<Login />} />
          <Route exact path="/register" element={<Register />} />
          <Route exact path="/forgot-password" element={<ForgotPassword />} />
          <Route exact path="/confirm-code" element={<ConfirmCode />} />
          <Route exact path="/reset-password" element={<ResetPassword />} />
          <Route path="*" element={<ErrorPage />} />
        </Routes>
      </div>
    </div>
  );
}

export default App;
