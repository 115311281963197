import React, { useState, useEffect } from "react";
import "./style.css";
import { Button } from "../Button/Button";
import ReactLogo from "../../logo-main.svg";
import user from "../../images/user.svg";
import menu from "../../images/menu.svg";
import cross from "../../images/cross.svg";
import { Link, useLocation, useNavigate } from "react-router-dom";

export const Header = () => {
  const [showProfileMenu, setShowProfileMenu] = useState(false);
  const [showSideMenu, setShowSideMenu] = useState(false);
  const [bottomShadowClass, setBottomShadowClass] = useState("");

  const location = useLocation();
  const isLoggedIn = localStorage.getItem("isLoggedIn");
  const navigate = useNavigate();
  const logout = () => {
    localStorage.setItem("isLoggedIn", false);
    navigate("/");
  };

  const toggleVisibility = () => {
    if (window.pageYOffset > 0) {
      setBottomShadowClass("bottom-shadow");
    } else {
      setBottomShadowClass("");
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", toggleVisibility);
    return () => {
      window.removeEventListener("scroll", toggleVisibility);
    };
  }, []);
  return (
    <div className={"header " + bottomShadowClass}>
      {showSideMenu && (
        <div className="mob-side-bar">
          <div className="mob-nav-bar">
            <Link to="#products" onClick={() => setShowSideMenu(false)}>
              <div className="mob-side-bar-item">Products</div>
            </Link>
            <Link to="#customers" onClick={() => setShowSideMenu(false)}>
              <div className="mob-side-bar-item">Customers</div>
            </Link>
            <Link to="#our-data" onClick={() => setShowSideMenu(false)}>
              <div className="mob-side-bar-item">Our Data</div>
            </Link>
            <Link to="#pricing" onClick={() => setShowSideMenu(false)}>
              <div className="mob-side-bar-item">Pricing</div>
            </Link>
            <Link to="#resources" onClick={() => setShowSideMenu(false)}>
              <div className="mob-side-bar-item">Resources</div>
            </Link>
          </div>
          <div className="mob-side-bar-btns">
            {isLoggedIn !== "true" ? (
              <>
                <div className="login-btn">
                  <Button
                    text="Get Started"
                    onClick={() => (
                      navigate("/register"), setShowSideMenu(false)
                    )}
                  />
                </div>
                <div className="light-btn">
                  <Button
                    text="Login"
                    onClick={() => (navigate("/login"), setShowSideMenu(false))}
                  />
                </div>
              </>
            ) : (
              <div className="login-btn">
                <Button
                  text="Close"
                  onClick={() => (
                    setShowSideMenu(false), setShowProfileMenu(false)
                  )}
                />
              </div>
            )}
          </div>
        </div>
      )}
      <div className="main-header">
        <Link to={"/"}>
          <img src={ReactLogo} alt="Logo" className="logo" />
        </Link>
        <div className="header-nav-bar">
          <Link to="#products">
            <div className="header-nav-bar-item">Products</div>
          </Link>
          <Link to="#customers">
            <div className="header-nav-bar-item">Customers</div>
          </Link>
          <Link to="#our-data">
            <div className="header-nav-bar-item">Our Data</div>
          </Link>
          <Link to="#pricing">
            <div className="header-nav-bar-item">Pricing</div>
          </Link>
          <Link to="#resources">
            <div className="header-nav-bar-item">Resources</div>
          </Link>
        </div>

        {isLoggedIn !== "true" ? (
          <div className="header-buttons">
            {location.pathname !== "/register" && (
              <div className="login-btn">
                <Button
                  text="Get Started"
                  onClick={() => navigate("/register")}
                />
              </div>
            )}
            {location.pathname !== "/login" && (
              <div className="light-btn">
                <Button text="Login" onClick={() => navigate("/login")} />
              </div>
            )}
          </div>
        ) : (
          <div
            className="profile-section"
            onClick={() => setShowProfileMenu(!showProfileMenu)}
          >
            <img src={user} alt="" />
            {showProfileMenu && (
              <div className="profile-dropdown">
                <div
                  className="profile-dropdown-option"
                  onClick={() => navigate("/dashboard")}
                >
                  Dashboard
                </div>
                <div
                  className="profile-dropdown-option"
                  onClick={() => logout()}
                >
                  Logout
                </div>
              </div>
            )}
          </div>
        )}
        {showSideMenu ? (
          <div className="mob-profile-section">
            {isLoggedIn === "true" ? (
              <div
                className="profile-section"
                onClick={() => setShowProfileMenu(!showProfileMenu)}
              >
                <img src={user} alt="" />
                {showProfileMenu && (
                  <div className="profile-dropdown">
                    <div
                      className="profile-dropdown-option"
                      onClick={() => (
                        navigate("/dashboard"), setShowSideMenu(false)
                      )}
                    >
                      Dashboard
                    </div>
                    <div
                      className="profile-dropdown-option"
                      onClick={() => (logout(), setShowSideMenu(false))}
                    >
                      Logout
                    </div>
                  </div>
                )}
              </div>
            ) : (
              <div
                className="mob-side-bar-cross"
                onClick={() => setShowSideMenu(false)}
              >
                <img src={cross} alt="" />
              </div>
            )}
          </div>
        ) : (
          <div className="mob-menu-icon" onClick={() => setShowSideMenu(true)}>
            <img src={menu} alt="" />
          </div>
        )}
      </div>
    </div>
  );
};
