import React from "react";
import { Button } from "../Button/Button";
import { useNavigate } from "react-router-dom";
import "./style.css";
import { Footer } from "../Footer/Footer";

export const ErrorPage = () => {
  const navigate = useNavigate();
  return (
    <>
      <div className="error-page">
        <div className="error-heading">404</div>
        <div className="error-label">Page Not Found!</div>
        <div className="error-description">
          The page you are looking for is not found or no longer exists.
        </div>
        <Button text="Back to home" onClick={() => navigate("/")} />
      </div>
      <Footer background="#dae1e6" color="#04263f" />
    </>
  );
};
