import React from "react";
import ContentLoader from "react-content-loader";

const ResultLoader = (props) => (
  <ContentLoader
    height={"280px"}
    width={"100%"}
    {...props}
    backgroundColor="#dae1e6"
    foregroundColor="grey"
  >
    <rect x="0" y="10" rx="4" ry="4" width="60%" height="18" />
    <rect x="0" y="35" rx="4" ry="4" width="100%" height="25" />
    <rect x="0" y="80" rx="4" ry="4" width="60%" height="18" />
    <rect x="0" y="105" rx="4" ry="4" width="100%" height="25" />
    <rect x="0" y="145" rx="4" ry="4" width="60%" height="18" />
    <rect x="0" y="170" rx="4" ry="4" width="100%" height="25" />
    <rect x="0" y="220" rx="4" ry="4" width="60%" height="18" />
    <rect x="0" y="245" rx="4" ry="4" width="100%" height="25" />
  </ContentLoader>
);

export default ResultLoader;
