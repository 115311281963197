import React, { useEffect, useState } from "react";
import { Selector } from "../../components/Selector/Selector";
import { Button } from "../../components/Button/Button";
import { Link, useNavigate } from "react-router-dom";
import { Footer } from "../../components/Footer/Footer";
import check from "../../images/check.webp";
import GradIcon from "../../Icon.svg";
import api from "../../AxiosInstance";
import { Loader } from "../../components/Loader/Loader";
import { ImCross } from "react-icons/im";
import "./style.css";
import { MainSelector } from "../../components/MainSelector/MainSelector";

export const Homepage = () => {
  const [departmentCount, setDepartmentCount] = useState(1);
  const [collegeList, setCollegeList] = useState([]);
  const [departmentList, setDepartmentList] = useState([]);
  const [companyList, setCompanyList] = useState([]);
  const [positionList, setPositionList] = useState([]);
  const [college, setCollege] = useState("");
  const [department, setDepartment] = useState("");
  const [college1, setCollege1] = useState("");
  const [department1, setDepartment1] = useState("");
  const [company, setCompany] = useState("");
  const [position, setPosition] = useState("");
  const [companyUid, setCompanyUid] = useState("");
  const [positionUid, setPositionUid] = useState("");
  const [collegeUid, setCollegeUid] = useState("");
  const [departmentUid, setDepartmentUid] = useState("");
  const [collegeUid1, setCollegeUid1] = useState("");
  const [departmentUid1, setDepartmentUid1] = useState("");
  const [showCollegeList, setShowCollegeList] = useState(false);
  const [showDepartmentList, setShowDepartmentList] = useState(false);
  const [showCollegeList1, setShowCollegeList1] = useState(false);
  const [showDepartmentList1, setShowDepartmentList1] = useState(false);
  const [showCompanyList, setShowCompanyList] = useState(false);
  const [showPositionList, setShowPositionList] = useState(false);
  const [selectedMainTab, setSelectedMainTab] = useState(1);
  const [selectedTab, setSelectedTab] = useState(1);
  const [selectedEducationId, setSelectedEducationId] = useState(null);
  const [selectedEducationId1, setSelectedEducationId1] = useState(null);
  const [selectedOrganizationId, setSelectedOrganizationId] = useState(null);

  const navigate = useNavigate();

  const addDepartment = () => {
    if (departmentCount < 2) {
      setDepartmentCount(departmentCount + 1);
    }
  };

  const closeDepartmentField = () => {
    if (departmentCount > 1) {
      setDepartmentCount(departmentCount - 1);
    }
  };

  const fetchEducations = (college) => {
    api
      .get("/fetchEducations", { params: { education: college } })
      .then((response) => {
        setCollegeList(response.data.data);
      })
      .catch((error) => {});
  };

  const fetchEducationDepartments = (educationId, department) => {
    api
      .get("/fetchEducationDepartments", {
        params: { educationId: educationId, department: department },
      })
      .then((response) => {
        setDepartmentList(response.data.data);
      })
      .catch((error) => {});
  };

  const fetchOrganizations = (company) => {
    api
      .get("/fetchOrganizations", { params: { company: company } })
      .then((response) => {
        setCompanyList(response.data.data);
      })
      .catch((error) => {});
  };

  const fetchOrganizationRoles = (organizationId, position) => {
    api
      .get("/fetchOrganizationRoles", {
        params: { organizationId: organizationId, position: position },
      })
      .then((response) => {
        setPositionList(response.data.data);
      })
      .catch((error) => {});
  };

  const handleCollegeChange = (e) => {
    setCollege(e.target.value);
    fetchEducations(e.target.value);
  };

  const handleDepartmentChange = (e) => {
    setDepartment(e.target.value);
    fetchEducationDepartments(selectedEducationId, e.target.value);
  };

  const handleCollege1Change = (e) => {
    setCollege1(e.target.value);
    fetchEducations(e.target.value);
  };

  const handleDepartment1Change = (e) => {
    setDepartment1(e.target.value);
    fetchEducationDepartments(selectedEducationId1, e.target.value);
  };

  const handleCompanyChange = (e) => {
    setCompany(e.target.value);
    fetchOrganizations(e.target.value);
  };

  const handlePositionChange = (e) => {
    setPosition(e.target.value);
    fetchOrganizationRoles(selectedOrganizationId, e.target.value);
  };

  const handleCompanyClick = () => {
    setShowCompanyList(true);
    setShowPositionList(false);
  };

  const handlePositionClick = () => {
    setShowCompanyList(false);
    setShowPositionList(true);
  };

  const handleCollegeClick = () => {
    setShowCollegeList(true);
    setShowDepartmentList(false);
    setShowCollegeList1(false);
    setShowDepartmentList1(false);
  };

  const handleDepartmentClick = () => {
    setShowDepartmentList(true);
    setShowCollegeList(false);
    setShowCollegeList1(false);
    setShowDepartmentList1(false);
  };

  const handleCollege1Click = () => {
    setShowCollegeList1(true);
    setShowCollegeList(false);
    setShowDepartmentList1(false);
    setShowDepartmentList(false);
  };

  const handleDepartment1Click = () => {
    setShowDepartmentList1(true);
    setShowCollegeList1(false);
    setShowCollegeList(false);
    setShowDepartmentList(false);
  };

  const selectCollege = (college) => {
    setCollegeUid(college.uid);
    setCollege(college.name);
    setShowCollegeList(false);
    setSelectedEducationId(college._id);
    fetchEducationDepartments(college._id, "");
  };

  const selectDepartment = (department) => {
    setDepartmentUid(department.uid);
    setDepartment(department.name);
    setShowDepartmentList(false);
  };

  const selectCollege1 = (college) => {
    setCollegeUid1(college.uid);
    setCollege1(college.name);
    setShowCollegeList1(false);
    setSelectedEducationId1(college._id);
    fetchEducationDepartments(college._id, "");
  };

  const selectDepartment1 = (department) => {
    setDepartmentUid1(department.uid);
    setDepartment1(department.name);
    setShowDepartmentList1(false);
  };

  const selectCompany = (company) => {
    setCompanyUid(company.uid);
    setCompany(company.name);
    setShowCompanyList(false);
    fetchOrganizationRoles(company._id, "");
  };

  const selectPosition = (position) => {
    setPositionUid(position.uid);
    setPosition(position.title);
    setShowPositionList(false);
  };

  const changeMainTab = (value) => {
    setSelectedMainTab(value);
    if (value == 1) {
      changeTab(1);
    } else {
      changeTab(3);
    }
  };

  const changeTab = (value) => {
    setSelectedTab(value);
    setShowCollegeList(false);
    setShowDepartmentList(false);
    setShowCollegeList1(false);
    setShowDepartmentList1(false);
    setShowCompanyList(false);
    setShowPositionList(false);
    setDepartmentCount(1);
    setDepartment("");
    setCollege("");
    setDepartment1("");
    setCollege1("");
    setCompany("");
    setPosition("");
    setDepartmentUid("");
    setCollegeUid("");
    setDepartmentUid1("");
    setCollegeUid1("");
    setCompanyUid("");
    setPositionUid("");
  };

  function setSubmitBtnDisable() {
    if (selectedMainTab == 1) {
      if (selectedTab == 1) {
        if (departmentCount == 1) {
          return college == "" || department == "";
        } else {
          return college1 == "" || department1 == "";
        }
      } else {
        if (departmentCount == 1) {
          return college == "";
        } else {
          return college1 == "";
        }
      }
    } else {
      if (selectedTab == 3) {
        return company == "" || position == "";
      } else {
        return company == "";
      }
    }
  }

  const handleSubmit = () => {
    const state = {
      college: college,
      college1: college1,
      department: department,
      department1: department1,
      company: company,
      position: position,
      departmentCount: departmentCount,
      selectedMainTab: selectedMainTab,
    };
    if (selectedMainTab == 1) {
      if (selectedTab == 1) {
        if (departmentCount == 1) {
          navigate(`/explore_department/${collegeUid}/${departmentUid}`, {
            state: state,
          });
        } else {
          navigate(
            `/compare_departments/${collegeUid}/${departmentUid}/${collegeUid1}/${departmentUid1}`,
            {
              state: state,
            }
          );
        }
      } else {
        if (departmentCount == 1) {
          navigate(`/explore_university/${collegeUid}`, {
            state: state,
          });
        } else {
          navigate(`/compare_universities/${collegeUid}/${collegeUid1}`, {
            state: state,
          });
        }
      }
    } else {
      if (selectedTab == 3) {
        navigate(`/explore_position/${companyUid}/${positionUid}`, {
          state: state,
        });
      } else {
        navigate(`/explore_company/${companyUid}`, {
          state: state,
        });
      }
    }
  };

  useEffect(() => {
    document.title = "Home - AfterGrad";
    fetchEducations();
    fetchOrganizations();
  }, []);

  return (
    <div className="homepage">
      <div className="inner-homepage">
        <div className="main-selector-section">
          <MainSelector
            changeMainTab={changeMainTab}
            selectedMainTab={selectedMainTab}
          />
        </div>
        <div className="headline">
          {selectedMainTab == 1
            ? "Decide your undergrad based on after grad statistics"
            : "Explore the educational paths that leads to your target company & position"}
        </div>
        <div className="description">
          {selectedMainTab == 1
            ? "Type the university departments you are considering and well show you what will happen after graduation."
            : "Type your target company & position and we'll show you the educational steps that you should follow to reach your goal."}
        </div>
        <Selector
          selectedMainTab={selectedMainTab}
          selectedTab={selectedTab}
          changeTab={changeTab}
        />
        <div className="analysis">
          <div className="college-department">
            <div className="analysis-fields">
              {(selectedTab == 1 || selectedTab == 2) && (
                <div className="input-box">
                  <div className="input-label">UNIVERSITY / COLLEGE</div>
                </div>
              )}
              {selectedTab == 1 && (
                <div className="input-box">
                  <div className="input-label">DEPARTMENT / SCHOOL</div>
                </div>
              )}
              {(selectedTab == 3 || selectedTab == 4) && (
                <div className="input-box">
                  <div className="input-label">COMPANY</div>
                </div>
              )}
              {selectedTab == 3 && (
                <div className="input-box">
                  <div className="input-label">POSITION</div>
                </div>
              )}
            </div>
            <div className="analysis-fields">
              {(selectedTab == 1 || selectedTab == 2) && (
                <div
                  className="input-box"
                  style={{ width: selectedTab == 2 ? "100%" : "48%" }}
                >
                  <div className="input-text">
                    <input
                      type="text"
                      className="input-field"
                      placeholder="Select university/college"
                      onChange={(e) => handleCollegeChange(e)}
                      value={college}
                      onClick={() => handleCollegeClick()}
                    />
                    {showCollegeList && (
                      <div className="option-list">
                        {collegeList.length > 0 &&
                          collegeList.map((college, index) => {
                            return (
                              <div
                                className="option"
                                key={index}
                                onClick={() => selectCollege(college)}
                              >
                                {college.name}
                              </div>
                            );
                          })}
                      </div>
                    )}
                    {selectedTab == 2 && departmentCount > 1 && (
                      <div
                        className="cross-btn"
                        onClick={() => closeDepartmentField()}
                      >
                        <ImCross />
                      </div>
                    )}
                  </div>
                </div>
              )}
              {selectedTab == 1 && (
                <div className="input-box">
                  <div className="input-text">
                    <input
                      type="text"
                      className="input-field"
                      placeholder="Select department/school"
                      onChange={(e) => handleDepartmentChange(e)}
                      value={department}
                      disabled={college == ""}
                      onClick={() => handleDepartmentClick()}
                    />
                    {showDepartmentList && (
                      <div className="option-list">
                        {departmentList.length > 0 &&
                          departmentList.map((department, index) => {
                            return (
                              <div
                                className="option"
                                key={index}
                                onClick={() => selectDepartment(department)}
                              >
                                {department.name}
                              </div>
                            );
                          })}
                      </div>
                    )}
                    {departmentCount > 1 && (
                      <div
                        className="cross-btn"
                        onClick={() => closeDepartmentField()}
                      >
                        <ImCross />
                      </div>
                    )}
                  </div>
                </div>
              )}
              {(selectedTab == 3 || selectedTab == 4) && (
                <>
                  <div
                    className="input-box"
                    style={{ width: selectedTab == 4 ? "100%" : "48%" }}
                  >
                    <div className="input-text">
                      <input
                        type="text"
                        className="input-field"
                        placeholder="Select company"
                        onChange={(e) => handleCompanyChange(e)}
                        value={company}
                        onClick={() => handleCompanyClick()}
                      />
                      {showCompanyList && (
                        <div className="option-list">
                          {companyList.length > 0 &&
                            companyList.map((company, index) => {
                              return (
                                <div
                                  className="option"
                                  key={index}
                                  onClick={() => selectCompany(company)}
                                >
                                  {company.name}
                                </div>
                              );
                            })}
                        </div>
                      )}
                    </div>
                  </div>
                  {selectedTab == 3 && (
                    <div className="input-box">
                      <div className="input-text">
                        <input
                          type="text"
                          className="input-field"
                          placeholder="Select position"
                          onChange={(e) => handlePositionChange(e)}
                          value={position}
                          disabled={company == ""}
                          onClick={() => handlePositionClick()}
                        />
                        {showPositionList && (
                          <div className="option-list">
                            {positionList.length > 0 &&
                              positionList.map((position, index) => {
                                return (
                                  <div
                                    className="option"
                                    key={index}
                                    onClick={() => selectPosition(position)}
                                  >
                                    {position.title}
                                  </div>
                                );
                              })}
                          </div>
                        )}
                        {departmentCount > 1 && (
                          <div
                            className="cross-btn"
                            onClick={() => closeDepartmentField()}
                          >
                            <ImCross />
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                </>
              )}
            </div>

            {(selectedTab == 1 || selectedTab == 2) && departmentCount == 1 && (
              <div className="add-dept">
                <button
                  className="add-dept-btn"
                  onClick={() => addDepartment()}
                >
                  {selectedTab == 1
                    ? "Add an other university / department"
                    : "Add an other university"}
                </button>
              </div>
            )}
            {(selectedTab == 1 || selectedTab == 2) && departmentCount == 2 && (
              <div className="analysis-fields">
                <div
                  className="input-box"
                  style={{ width: selectedTab == 2 ? "100%" : "48%" }}
                >
                  <div className="input-text">
                    <input
                      type="text"
                      className="input-field"
                      placeholder="Select university/college"
                      onChange={(e) => handleCollege1Change(e)}
                      value={college1}
                      onClick={() => handleCollege1Click()}
                      disabled={
                        selectedTab == 1
                          ? college == "" || department == ""
                          : college == ""
                      }
                    />
                    {showCollegeList1 && (
                      <div className="option-list">
                        {collegeList.length > 0 &&
                          collegeList.map((college, index) => {
                            return (
                              <div
                                className="option"
                                key={index}
                                onClick={() => selectCollege1(college)}
                              >
                                {college.name}
                              </div>
                            );
                          })}
                      </div>
                    )}
                    {selectedTab == 2 && departmentCount > 1 && (
                      <div
                        className="cross-btn"
                        onClick={() => closeDepartmentField()}
                      >
                        <ImCross />
                      </div>
                    )}
                  </div>
                </div>
                {selectedTab == 1 && (
                  <div className="input-box">
                    <div className="input-text">
                      <input
                        type="text"
                        className="input-field"
                        placeholder="Select department/school"
                        onChange={(e) => handleDepartment1Change(e)}
                        value={department1}
                        disabled={college1 == ""}
                        onClick={() => handleDepartment1Click()}
                      />
                      {showDepartmentList1 && (
                        <div className="option-list">
                          {departmentList.length > 0 &&
                            departmentList.map((department, index) => {
                              return (
                                <div
                                  className="option"
                                  key={index}
                                  onClick={() => selectDepartment1(department)}
                                >
                                  {department.name}
                                </div>
                              );
                            })}
                        </div>
                      )}
                      {departmentCount > 1 && (
                        <div
                          className="cross-btn"
                          onClick={() => closeDepartmentField()}
                        >
                          <ImCross />
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
          <div
            className={"run-button"}
            style={{
              marginBottom:
                departmentCount == 2 || selectedTab == 3 || selectedTab == 4
                  ? "10px"
                  : "",
            }}
            onClick={handleSubmit}
          >
            <Button
              text={departmentCount == 2 ? "COMPARE" : "EXPLORE"}
              icon={GradIcon}
              disabled={setSubmitBtnDisable()}
            />
          </div>
        </div>
        <div className="content-container">
          <div className="content-text">
            <div className="content-heading">
              <div className="tick-img">
                <img src={check} alt="" />
              </div>
              <div className="content-heading-text">
                Explore & compare university departments by statistics of their
                graduates
              </div>
            </div>
            <div className="content-list">
              <div className="dot-container">
                <div className="content-list-dot"></div>
              </div>
              <div className="content-list-text">
                Explore the after graduation carreer distribution of the
                graduates of a specific university department.
              </div>
            </div>
            <div className="content-list">
              <div className="dot-container">
                <div className="content-list-dot"></div>
              </div>
              <div className="content-list-text">
                Compare university departments that you are considering
                according to after graduation statistics of their graduates.
              </div>
            </div>
          </div>
          <div className="content-text">
            <div className="content-heading">
              <div className="tick-img">
                <img src={check} alt="" />
              </div>
              <div className="content-heading-text">
                Explore & compare universities by statistics of their graduates
              </div>
            </div>
            <div className="content-list">
              <div className="dot-container">
                <div className="content-list-dot"></div>
              </div>
              <div className="content-list-text">
                Find out which companies and positions you can work in if you
                graduate from the university you are considering choosing.
              </div>
            </div>
          </div>
          <div className="content-text">
            <div className="content-heading">
              <div className="tick-img">
                <img src={check} alt="" />
              </div>
              <div className="content-heading-text">
                Targeting a specific position-job in a specific company?
              </div>
            </div>
            <div className="content-list">
              <div className="dot-container">
                <div className="content-list-dot"></div>
              </div>
              <div className="content-list-text">
                Compare & explore the ideal university departments for a
                specific position in a specific company.
              </div>
            </div>
            <div className="content-list">
              <div className="dot-container">
                <div className="content-list-dot"></div>
              </div>
              <div className="content-list-text">
                Learn the characteristic carreer patterns that you can follow to
                reach a specific position in a specific company.
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};
