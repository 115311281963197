import React from "react";
import "./style.css";

export const MainSelector = (props) => {
  const { changeMainTab, selectedMainTab } = props;
  return (
    <div className="main-selector">
      <div
        className={"selector-option " + `${selectedMainTab == 1 && "selected"}`}
        onClick={() => changeMainTab(1)}
      >
        DEPARTMENTS & UNIVERSITIES
      </div>
      <div
        className={"selector-option " + `${selectedMainTab == 2 && "selected"}`}
        onClick={() => changeMainTab(2)}
      >
        POSITIONS(JOBS) & COMPANIES
      </div>
    </div>
  );
};
